import sidebar_logo from "../images/sidebar_logo.svg";

export default {
  colorPrimary: "#215054",
  comparison_products_colors: ["#4B4747", "#2C9FE0", "#FFC700"],
  income_chart_with_types_navbar_button_background: "#fff",
  income_chart_with_types_navbar_button_color: "#1E1919",
  income_chart_with_types_navbar_container_background: "#749B9E",
  proposal_header_padding: 0,
  proposal_header_height: 0,
  sidebar_avatar_background: "#e3ebeb",
  sidebar_avatar_color: "#1E1919",
  sidebar_background: "#749B9E",
  sidebar_icon_color_active: "#1E1919",
  sidebar_icon_layout_vertical: true,
  sidebar_logo: sidebar_logo,
  sidebar_menu_item_hover_background: "transparent",
  sidebar_width: 100,
  tenant_color_primary: "#88D2DC",
};
