import { lazy } from "react";

const Dashboard = lazy(() => import("../index"));
const PageAura = lazy(() => import("../pages/PageAura"));
const PageAuraDemo = lazy(() => import("../pages/PageAuraDemo"));
const PageNotFound = lazy(() => import("../../../page/PageNotFound"));
const PageWelcome = lazy(() => import("../pages/PageWelcome"));

export default {
  "": PageWelcome,
  "/aura": PageAura,
  "/aura-demo": PageAuraDemo,
  "/halo/dashboard": Dashboard,
  "*": PageNotFound,
};
