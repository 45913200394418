export const DEFAULT_FILTER_ASSET_MANAGERS_LEVEL = ["Level &reg;"];

export const LEAD_MAX_RATE_VALUE = 5;

export const PDF_PAGE_LIST = [
  {
    type: "growth",
    nodeIdCluster: [
      "reportPageCover",
      "reportPageIntroduction",
      "reportPageProposalGrowth",
      "reportPageProposalHowToGet",
      "reportPageProposalDisclosure",
    ],
  },
  {
    type: "income",
    nodeIdCluster: [
      "reportPageCover",
      "reportPageIntroduction",
      "reportPageProposalIncome",
      "reportPageProposalHowToGet",
      "reportPageProposalDisclosure",
    ],
  },
];

export const PROPOSAL_DEFAULT_DATA_LEVEL = {
  birthYear: 1972,
  contributions: 12000,
  investmentAmount: 500000,
  investmentDuration: 20,
  investmentObjective: "growth",
  organization: "level2",
  riskTolerance: "Moderate",
  withdrawalLevel: "4%",
  yearToStartWithdrawals: 1,
};

export const PROPOSAL_GOAL_TYPE_LEVEL = [
  {
    value: "growth",
    label: "Drive Growth",
  },
  {
    value: "income",
    label: "Drive Income",
  },
  {
    value: "growth_income",
    label: "Drive Growth & Income",
  },
];

export const PROPOSAL_SAMPLE_URL_LEVEL =
  "https://ontrajectory.s3.amazonaws.com/level/level_proposal_sample.pdf";

export const RISK_TYPES_LEVEL = [
  { value: "aggressive", label: "Aggressive" },
  { value: "growth", label: "Growth" },
  { value: "moderate", label: "Moderate" },
  { value: "conservative", label: "Conservative" },
  { value: "preservation", label: "Preservation" },
];
