import styled from "styled-components";

const StylesContainer = styled.div`
  .page-container {
    box-sizing: border-box;
    font-family: Inter, sans-serif;
    position: relative;
    width: 1230px;
    padding: 71px 80px 0 78px;
  }

  h3 {
    color: #2a2a2a;
    font-size: 31px;
    font-style: normal;
    font-weight: 700;
    line-height: 160%;
    letter-spacing: -0.62px;
    margin: 42px 0 32px;
  }

  main {
    display: flex;
    gap: 64px;

    p {
      color: #2a2a2a;
      font-size: 18px;
      font-style: normal;
      font-weight: 400;
      line-height: 180%;
      letter-spacing: -0.36px;
      margin: 0;
    }

    .demo-description-container {
      background: #fff;
      border-radius: 12px;
      border: 1px solid rgba(0, 0, 0, 0.25);
      padding: 24px;
      position: relative;
      top: -54px;
      width: 366px;

      h3 {
        font-size: 29px;
        margin: 0;
      }

      p {
        font-size: 19px;
      }
    }
  }

  footer {
    background: #e6f4ef;
    border-radius: 12px;
    display: flex;
    flex-direction: column;
    gap: 22px;
    padding: 24px;

    h4 {
      color: #02494e;
      font-size: 18px;
      font-style: normal;
      font-weight: 800;
      line-height: normal;
      margin: 0;
    }

    p {
      color: #2a2a2a;
      font-size: 16px;
      font-style: normal;
      font-weight: 400;
      line-height: 160%;
      letter-spacing: -0.32px;
      margin: 0;
    }
  }
`;

export default StylesContainer;
