import { Suspense } from "react";
import { BrowserRouter as Router } from "react-router-dom";
import { Spin } from "antd";
import Routing from "./Routing";
import SentryProvider from "./SentryProvider";
import Trackers from "./Trackers";
import UI from "./UI";

import themeBasic from "../theme/global";

const App = ({ children }) => {
  return (
    <SentryProvider>
      <UI themeConfig={themeBasic}>
        <Router>
          <Suspense fallback={<Spin fullscreen size="large" spinning />}>
            <Trackers>
              <Routing>{children}</Routing>
            </Trackers>
          </Suspense>
        </Router>
      </UI>
    </SentryProvider>
  );
};

export default App;
