import { useContext } from "react";
import State from "../../../../../context";
import { Flex } from "antd";
import ReportPageCover from "../../page/ReportPageCover";
import ReportPageIntroduction from "../../page/ReportPageIntroduction";
import ReportPageProposalDisclosure from "../../page/ReportPageProposalDisclosure";
import ReportPageProposalGrowth from "../../page/ReportPageProposalGrowth";
import ReportPageProposalHowToGet from "../../page/ReportPageProposalHowToGet";
import ReportPageProposalIncome from "../../page/ReportPageProposalIncome";

import { setPhoneMask } from "../../../../../utils/helper/general";

const ProposalPdfContentHolder = ({ activeGoalData }) => {
  const [state] = useContext(State);

  const personaName = `${state.personalInfo?.firstName} ${state.personalInfo?.lastName}`;

  const managerEmail = state.userManagerData?._id;

  const managerName = `${
    state.userManagerData?.personalInfo?.firstName ?? ""
  } ${state.userManagerData?.personalInfo?.lastName ?? ""}`;

  const managerPhone = setPhoneMask(
    state.userManagerData?.personalInfo?.phoneNumber
  );

  return (
    <div id="pdfContentHolder" style={{ display: "none" }}>
      <Flex gap={32} vertical>
        <ReportPageCover
          managerEmail={managerEmail}
          managerName={managerName}
          managerPhone={managerPhone}
          personaName={personaName}
        />
        <ReportPageIntroduction
          managerEmail={managerEmail}
          managerName={managerName}
          managerPhone={managerPhone}
          personaName={personaName}
        />
        {activeGoalData?.investmentObjective === "growth" ? (
          <ReportPageProposalGrowth
            personaName={personaName}
            proposalData={activeGoalData}
            productsList={state.productsList}
            setLoading={loading => state.setKeyValue("loading", loading)}
          />
        ) : (
          <ReportPageProposalIncome
            personaName={personaName}
            proposalData={activeGoalData}
            setLoading={loading => state.setKeyValue("loading", loading)}
            productsList={state.productsList}
          />
        )}
        <ReportPageProposalHowToGet
          personaName={personaName}
          productsList={state.productsList}
          proposalData={activeGoalData}
        />
        <ReportPageProposalDisclosure personaName={personaName} />
      </Flex>
    </div>
  );
};

export default ProposalPdfContentHolder;
