import { createContext, useContext, useEffect, useMemo, useState } from "react";
import State from "../../../../context";
import { Button, Checkbox, Flex, Image, Table } from "antd";
import { DndContext } from "@dnd-kit/core";
import { restrictToVerticalAxis } from "@dnd-kit/modifiers";
import {
  arrayMove,
  SortableContext,
  useSortable,
  verticalListSortingStrategy,
} from "@dnd-kit/sortable";
import { CSS } from "@dnd-kit/utilities";
import OverlayPdf from "../../pdf/component/OverlayPdf";
import ReportPdfContentHolder from "../../pdf/template/ReportPdfContentHolder";

import StylesContainer from "./styles";

import { PDF_PAGE_LIST } from "../../constant";

import { getHtml2pdfPromiseList } from "../../../../utils/pdf";

import Close from "../../../../icon/Close";
import { ReactComponent as Reorder } from "./image/icon_reorder.svg";

const RowContext = createContext({});

const DragHandle = () => {
  const { setActivatorNodeRef, listeners } = useContext(RowContext);
  return (
    <Button
      icon={<Reorder />}
      type="text"
      size="small"
      style={{
        cursor: "move",
      }}
      ref={setActivatorNodeRef}
      {...listeners}
    />
  );
};

const Row = props => {
  const {
    attributes,
    isDragging,
    listeners,
    setActivatorNodeRef,
    setNodeRef,
    transform,
    transition,
  } = useSortable({
    id: props["data-row-key"],
  });
  const style = {
    ...props.style,
    transform: CSS.Translate.toString(transform),
    transition,
    ...(isDragging
      ? {
          position: "relative",
          zIndex: 9999,
        }
      : {}),
  };
  const contextValue = useMemo(
    () => ({
      setActivatorNodeRef,
      listeners,
    }),
    [setActivatorNodeRef, listeners]
  );
  return (
    <RowContext.Provider value={contextValue}>
      <tr {...props} ref={setNodeRef} style={style} {...attributes} />
    </RowContext.Provider>
  );
};

const ModalCreateReport = ({ onCancel, open }) => {
  const [state] = useContext(State);
  const [dataSource, setDataSource] = useState(
    PDF_PAGE_LIST.map(it => ({
      ...it,
      disabled: true,
      visible: false,
    }))
  );
  const [showOverlay, setShowOverlay] = useState(false);

  const isMarried = state.getPreferenceValue("guideInitialData")?.isMarried;

  useEffect(() => {
    if (open) {
      let visiblePagesList = [];

      //get visible pdf pages based on filling of the application by custom data
      if (state.getPreferenceValue("prospectObjective")) {
        if (state.getPreferenceValue("productMap")) {
          // saved ipq and proposal
          visiblePagesList = [
            ...visiblePagesList,
            "cover_page",
            "introduction",
            "proposal_product_selection",
            "ipq",
            "fact_sheets",
            "disclosures",
          ];
        } else {
          // saved ipq only
          visiblePagesList = [
            ...visiblePagesList,
            "cover_page",
            "introduction",
            "ipq",
            "disclosures",
          ];
        }
      }

      if (isMarried) {
        if (
          state.getPreferenceValue("perspectiveMy")?.selectedPersonality &&
          state.getPreferenceValue("perspectiveSpouse")?.selectedPersonality
        ) {
          // saved perspectives for user and spouse
          visiblePagesList = [
            ...visiblePagesList,
            "cover_page",
            "introduction",
            "perspectives",
            "disclosures",
          ];
        }
      } else {
        if (state.getPreferenceValue("perspectiveMy")?.selectedPersonality) {
          // saved perspectives for user
          visiblePagesList = [
            ...visiblePagesList,
            "cover_page",
            "introduction",
            "perspectives",
            "disclosures",
          ];
        }
      }

      if (
        state.getPreferenceValue("prioritiesMy") ||
        state.getPreferenceValue("prioritiesFamilyQuickAdd")
      ) {
        // saved priorities
        visiblePagesList = [
          ...visiblePagesList,
          "cover_page",
          "introduction",
          "priorities",
          "disclosures",
        ];
      }

      if (state.getPreferenceValue("milestonesQuestions")) {
        // saved milestones
        visiblePagesList = [
          ...visiblePagesList,
          "cover_page",
          "introduction",
          "milestones",
          "disclosures",
        ];
      }

      setDataSource(lastState =>
        lastState.map(elem =>
          [...new Set(visiblePagesList)].includes(elem.key)
            ? {
                ...elem,
                visible: true,
                disabled: false,
              }
            : {
                ...elem,
                visible: false,
                disabled: true,
              }
        )
      );
    }
  }, [open]);

  const onDragEnd = ({ active, over }) => {
    if (active.id !== over?.id) {
      setDataSource(prevState => {
        const activeIndex = prevState.findIndex(
          record => record.key === active?.id
        );
        const overIndex = prevState.findIndex(
          record => record.key === over?.id
        );
        return arrayMove(prevState, activeIndex, overIndex);
      });
    }
  };

  const handleGeneratePdf = () => {
    setShowOverlay(true);

    getHtml2pdfPromiseList(dataSource.filter(pageData => pageData.visible))
      .output("bloburl")
      .then(function (pdf) {
        let pdfWindow = window.open("", "_blank");
        pdfWindow.document.write(
          "<iframe  width='100%' height='100%' src='" + pdf + "'></iframe>"
        );
        pdfWindow.document.title = "OneAscent PDF";

        setShowOverlay(false);
      });
  };

  const toggleVisibility = (key, isChecked) =>
    setDataSource(lastState =>
      lastState.map(it =>
        it.key === key
          ? {
              ...it,
              visible: isChecked,
            }
          : it
      )
    );

  return (
    <StylesContainer
      closeIcon={<Close />}
      footer={
        <Flex justify="space-between">
          <Button onClick={onCancel} shape="round">
            Cancel
          </Button>
          <Button
            disabled={
              state.loadingPdfContent ||
              !dataSource?.filter(it => it.visible)?.length
            }
            onClick={handleGeneratePdf}
            shape="round"
          >
            Generate PDF
          </Button>
        </Flex>
      }
      onCancel={onCancel}
      open={open}
      title="PDF Options"
      width={location.origin.includes("localhost") ? 1270 : 548}
      zIndex={1000}
    >
      <DndContext modifiers={[restrictToVerticalAxis]} onDragEnd={onDragEnd}>
        <SortableContext
          items={dataSource.map(i => i.key)}
          strategy={verticalListSortingStrategy}
        >
          <Table
            components={{
              body: {
                row: Row,
              },
            }}
            dataSource={dataSource}
            pagination={false}
            rowKey="key"
          >
            <Table.Column
              dataIndex="Show"
              render={(_, allValues) => (
                <Checkbox
                  checked={allValues.visible}
                  disabled={allValues.disabled}
                  onChange={e =>
                    toggleVisibility(allValues.key, e.target.checked)
                  }
                />
              )}
              title="Show"
              width={68}
            />
            <Table.Column
              className="preview-image-cell"
              dataIndex="previewImage"
              render={src => <Image preview={false} src={src} />}
              width={50}
            />
            <Table.Column dataIndex="label" title="Section" />
            <Table.Column
              align="center"
              className="drag-icon-cell"
              key="sort"
              render={() => <DragHandle />}
              title="Order"
              width={70}
            />
          </Table>
        </SortableContext>
      </DndContext>
      <ReportPdfContentHolder />

      <OverlayPdf show={showOverlay} />
    </StylesContainer>
  );
};

export default ModalCreateReport;
